<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="news"
      :search="search"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Notícias</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              @click="prepareToDelete(item)"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"

          value
          disabled
        />
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      persistent
    >
      <form-news
        v-if="dialog"
        :populate-with="newsToEdit"
        @news-added="addNews"
        @news-edited="editNews"
        @close-dialog="closeDialog"
      />
    </v-dialog>
    <delete-confirmation
      :visible="dialogDeleteConfirmation"
      @cancel="dialogDeleteConfirmation = false"
      @confirm="deleteItem(item)"
    />
  </div>
</template>

<script>
import NewsService from '../../../services/news.service';
import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'News',
  components: {
    'form-news': () => import('./Form'),
    DeleteConfirmation,
  },
  data: () => ({
    headers: [
      {
        text: 'Ativo',
        align: 'start',
        sortable: false,
        value: 'active',
      },
      {
        text: 'Título',
        sortable: false,
        value: 'headline',
      },
      {
        text: 'Autor',
        sortable: false,
        value: 'author',
      },
      { text: 'Data de Criação', value: 'createdAt', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    news: [],
    search: '',
    dialog: false,
    newsToEdit: {},
    dialogDeleteConfirmation: false,
    dialogDelete: true,
    deleteItemID: '',
  }),

  async created() {
    await this.findAllNews();
  },

  methods: {
    async findAllNews() {
      const data = await NewsService.getNews();
      this.news = data.items;
    },
    closeDialog() {
      this.dialog = false;
      this.newsToEdit = {};
    },
    editItem(item) {
      this.newsToEdit = item;
      this.dialog = true;
    },
    prepareToDelete(item) {
      this.dialogDeleteConfirmation = true;
      this.deleteItemID = item.id;
    },
    async deleteItem() {
      try {
        this.dialogDeleteConfirmation = false;
        const itemId = this.deleteItemID;
        await NewsService.deleteNews(itemId);
        await this.findAllNews();
        this.$toast.success('Notícia excluída com sucesso');
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    async addNews() {
      await this.findAllNews();
    },

    async editNews() {
      await this.findAllNews();
    },
  },
};
</script>
