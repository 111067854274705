import api from '@/services/api';

const url = 'news';

const getNews = () => api.get(url);

const createNews = (news) => api.post(url, news);

const updateNews = (news) => api.put(url, news);

const deleteNews = (id) => api.delete(`${url}/${id}`);

export default {
  getNews, createNews, updateNews, deleteNews,
};
